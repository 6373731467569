import { useNavigate } from "react-router-dom";

import { questionRoute } from "../../Routes/constants";

import {
  ConfirmationBox,
  ConfirmationHeaderText,
  ContentText,
  ContentMessageText,
  NextButton,
} from "./styles";

export const Dataprocessing = () => {
  const navigate = useNavigate();
  return (
    <>
      <ConfirmationBox>
        <ConfirmationHeaderText color="textPrimary">
          Schedule a meeting with this {""}
          <a href="https://calendly.com/sotira-qualification-call/15min?back=1&month=2023-12">
            {" "}
            link{" "}
          </a>
        </ConfirmationHeaderText>
        <ContentText color="textPrimary">
          Feel free to email {""}
          <a href="mailto:support@sotira.co">support@sotira.co </a>
          {""} or call{""}
          <span style={{ color: "#0D6EFD" }}> (650) 485-3603</span>
          {""} if you have any questions.
        </ContentText>
        <ContentMessageText color="textPrimary">
          Continue to answer some questions and upload data for faster
          processing.
        </ContentMessageText>
        <NextButton onClick={() => navigate(questionRoute)}>Next</NextButton>
      </ConfirmationBox>
    </>
  );
};
