import { Table, TableCell, Typography, styled } from "@mui/material";
import { Document } from "react-pdf";

export const DataPreviewBox = styled("div")`
  width: 100%;
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;

export const DocuBorder = styled(Document)``;

export const ProgressDiv = styled("div")`
  text-align: center;
  margin-top: 20px;
`;

export const DocuDiv = styled("div")`
  box-shadow: var(--shadow);
  border: 1px solid #ccc !important;
  border-radius: 5px !important;
`;

export const PdfBox = styled("div")`
  max-height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const DataPreviewText = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #171717;
  font-size: 35px;
  font-style: normal;
  font-family: "ProximaNova" !important;
  font-weight: 700;
  line-height: 124%;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

export const ItemNameText = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #171717;
  font-size: 25px;
  font-style: normal;
  font-family: "ProximaNova" !important;
  font-weight: 700;
  line-height: 124%;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

export const ConfirmationText = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #171717;
  font-size: 25px;
  font-style: normal;
  font-family: "ProximaNova" !important;
  font-weight: 700;
  line-height: 124%;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

export const PreviewBox = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

export const BoldText = styled("span")`
  font-weight: 700 !important;
`;

export const NewText = styled("span")`
  font-weight: 700 !important;
  color: red !important;
`;

export const ContentText = styled("div")`
  color: #343434;
  font-size: 22px;
  font-family: "ProximaNova-Medium" !important;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const Datatable = styled("div")`
  gap: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 75px;
  max-width: 692px;
  width: 100%;
  @media (max-width: 1280px) and (min-width: 768px) {
    gap: 50px;
    margin-top: 50px;
  }
  @media (max-width: 393px) {
    gap: 45px;
    margin-top: 44px;
  }
`;

export const NextButton = styled("button")`
  background-color: #48b7a7;
  color: white;
  font-weight: 700;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: 1px solid;
  padding: 15px 25px;
  border-radius: 5px;
  font-size: 22px;
  font-family: "ProximaNova", sans-serif;
  gap: 10px;
  max-width: 300px;
  min-width: 200px;
  width: 100%;
  height: 55px;
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 18px;
  }
  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`;

export const DownloadButton = styled("button")`
  background-color: #48b7a7;
  color: white;
  font-weight: 700;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: 1px solid;
  padding: 15px 25px;
  border-radius: 5px;
  font-size: 22px;
  font-family: "ProximaNova !important";
  gap: 10px;
  max-width: 300px;
  min-width: 200px;
  width: 100%;
  height: 55px;
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const RejButton = styled("button")`
  background-color: #ff6767;
  color: white;
  font-weight: 700;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: 1px solid;
  padding: 15px 25px;
  border-radius: 5px;
  font-size: 22px;
  font-family: "ProximaNova !important";
  gap: 10px;
  max-width: 300px;
  min-width: 200px;
  width: 100%;
  height: 55px;
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const SuccessButton = styled("button")`
  background-color: #48b7a7;
  color: white;
  font-weight: 700;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: 1px solid;
  padding: 15px 25px;
  border-radius: 5px;
  font-size: 22px;
  font-family: "ProximaNova !important";
  gap: 10px;
  max-width: 500px;
  min-width: 200px;
  width: 100%;
  height: 55px;
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const CheckboxContainer = styled("div")`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const CheckboxLabel = styled(Typography)`
  font-size: 16px;
  font-family: "ProximaNova", sans-serif;
`;

export const ContextHeading = styled("div")`
  margin-top: 102px;
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 32px 25px;
  border: solid 1px #e1e1e1;
  background-color: #fff;
  text-align: center;
`;

export const HeadingText = styled("div")`
  width: 173px;
  height: 42px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 24px;
  border-radius: 5px;
`;

export const NeoDiv = styled("div")`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const NeoCheckDiv = styled("div")`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const CostText = styled("div")`
  font-size: 25px;
  font-weight: bold;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 24px;
  border-radius: 5px;
`;

export const PrevCostText = styled("div")`
  font-size: 16px;
  font-weight: bold;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 24px;
  border-radius: 5px;
`;

export const ButtonBox = styled("div")`
  max-width: 900px;
  width: 100%;
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ActionBox = styled("div")`
  max-width: 900px;
  width: 100%;
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ImgContainer = styled("img")``;

export const DataUploadBox = styled("div")`
  max-width: 680px;
  width: 100%;
  margin: auto;
  height: 100%;
  min-height: 358px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (max-width: 768px) {
    min-height: 425px;
  }
`;

export const DataUploadText = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #171717;
  font-size: 35px;
  font-family: "ProximaNova" !important;
  font-style: normal;
  font-weight: 700;
  line-height: 124%;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

export const UploadBox = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

export const InputNeo = styled("input")``;

export const StyledTableHeader = styled(TableCell)({
  fontSize: "14px",
  fontWeight: 700,
  fontFamily: "ProximaNova, sans-serif",
  borderRight: "1px solid #7C7C7C",
  borderTop: "1px solid #7C7C7C",
  borderBottom: "none",
  "&:last-child": {
    borderRight: "none",
  },
});

export const StyledTable = styled(Table)({
  borderRadius: "5px !important",
  boxShadow: "0px 3px 15px 5px rgba(0,0,0,.1) !important",
});

export const StyledTableContainer = styled(Table)({
  width: "100%",
  borderRadius: "5px !important",
  boxShadow: "0px 3px 15px 5px rgba(0,0,0,.1) !important",
  marginTop: "20px",
});
