import { useState, useEffect } from "react";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  useTheme,
  Box,
  Card,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import LocPopup from "./locationPop";

import getUser from "../../Utils/getUser";

import {
  ContextHeading,
  DataPreviewBox,
  CostText,
  NextButton,
  Container,
  Title,
  SubTitle,
  SectionTitle,
  ThemeOption,
  ThemeIndicator,
  ActiveIndicator,
  DarkThemeOption,
  SystemThemeOption,
  SystemIndicator,
} from "./styles";

interface Location {
  _id: any;
  name: any;
  address: any;
  city: any;
  state: any;
  zip: any;
}

export default function Tos(props: any) {
  const logged_user = getUser();
  const theme = useTheme();
  const [addLocation, setAddLocation] = useState(false);
  const [openLocModal, setOpenLocModal] = useState(false);
  const [locations, setLocations] = useState<Location[]>([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [primary, setPrimary] = useState("");

  const mode = localStorage.getItem("themeMode");

  useEffect(() => {
    const fetchData = async () => {
      try {
        fetchLocations();
      } catch (error) {
        console.error("Error grabbing tos data:", error);
      }
    };

    fetchData();
  }, []);

  const fetchLocations = async () => {
    const response = await axios.get(
      process.env.REACT_APP_API_BASE_URL + "user/get_all_loc",
      { params: { userID: logged_user } }
    );
    if (response.data.locations) {
      setLocations(response.data.locations);
      if (response.data.locations.length === 0) {
        setAddLocation(true);
      } else {
        setAddLocation(false);
      }
      return response.data.locations;
    } else {
      return [];
    }
  };

  const handleLocationSelect = (event: any) => {
    const selectedValue = event.target.value;
    setSelectedLocation(selectedValue);
    setPrimary(selectedValue);
    if (selectedValue === "Add Location") {
      setAddLocation(true);
    } else {
      setAddLocation(false);
    }
  };

  const handleLocOpen = () => {
    setOpenLocModal(true);
  };

  const handleLocClose = () => {
    setOpenLocModal(false);
    // const all_locations = fetchLocations();
  };

  const showLocationAction = () => {
    if (addLocation) {
      return <NextButton onClick={handleLocOpen}>Add New Location</NextButton>;
    }
    if (selectedLocation === primary) {
      return (
        <NextButton onClick={handleLocOpen} disabled>
          Already is primary location
        </NextButton>
      );
    } else {
      return (
        <NextButton onClick={handleLocOpen}>Set as primary location</NextButton>
      );
    }
  };

  const showOptions = () => {
    return (
      <div>
        <Typography
          color="textPrimary"
          sx={{ fontSize: "22px", fontFamily: "ProximaNova" }}
        >
          Set a primary or add a location:
        </Typography>
        <select
          id="location-select"
          value={selectedLocation}
          onChange={handleLocationSelect}
          style={{
            width: "100%",
            padding: "10px",
            margin: "10px 0",
            borderRadius: "5px",
            border: "1px solid #ccc",
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
          }}
        >
          {locations.length === 0 ? (
            <option value="Add Location">Add Location</option>
          ) : (
            <>
              {locations.map((location, index) => (
                <option key={index} value={location?._id}>
                  {location?.name} - {location?.address}
                </option>
              ))}
              <option value="Add Location">Add Location</option>
            </>
          )}
        </select>
        {showLocationAction()}
      </div>
    );
  };

  const showLocation = () => {
    return (
      <>
        <Dialog
          open={openLocModal}
          onClose={handleLocClose}
          maxWidth="md"
          fullWidth
          PaperProps={{
            style: {
              overflowX: "hidden",
              maxWidth: "665px",
              width: "100%",
            },
          }}
        >
          <DialogTitle>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleLocClose}
              aria-label="close"
              sx={{
                position: "absolute",
                right: 8,
                top: 0,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <LocPopup onClose={handleLocClose} />
          </DialogContent>
        </Dialog>
        <DataPreviewBox>
          <div> {showOptions()} </div>
        </DataPreviewBox>
      </>
    );
  };

  const handleThemeChange = (selectedMode: string) => {
    localStorage.setItem("themeMode", selectedMode);
    dispatchEvent(new Event("storage"));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box>
        <ContextHeading>
          <Typography color="textPrimary" sx={{ fontSize: "20px" }}>
            Settings
          </Typography>
        </ContextHeading>
        <Card
          sx={{
            padding: "20px 40px",
            width: "100%",
            marginBottom: "10px",
            boxShadow:
              "0px 1px 6px 1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
          }}
        >
          <CostText color="textPrimary"> Location Settings </CostText>
          <div style={{ paddingBottom: "20px" }}> {showLocation()} </div>
        </Card>

        <Container>
          <Title color="textPrimary">Appearance</Title>
          <SubTitle color="textPrimary">
            Customize your theme for a tailored experience
          </SubTitle>
          <SectionTitle color="textPrimary">Theme</SectionTitle>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <Box>
              <ThemeOption
                mode={mode}
                onClick={() => handleThemeChange("light")}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: "5px",
                    paddingBottom: "15px",
                  }}
                >
                  <ThemeIndicator />
                  <Box
                    sx={{
                      backgroundColor: "#D3D6D7",
                      height: "15px",
                      flexGrow: 1,
                      borderRadius: "5px",
                    }}
                  ></Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: "5px",
                    paddingBottom: "15px",
                  }}
                >
                  <ThemeIndicator />
                  <Box
                    sx={{
                      backgroundColor: "#D3D6D7",
                      height: "15px",
                      flexGrow: 1,
                      borderRadius: "5px",
                    }}
                  ></Box>
                </Box>
                <ActiveIndicator />
              </ThemeOption>
              <Typography
                color="textPrimary"
                sx={{ padding: "5px", textAlign: "center" }}
              >
                Light Mode
              </Typography>
            </Box>

            <Box>
              <DarkThemeOption
                mode={mode}
                onClick={() => handleThemeChange("dark")}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: "5px",
                    paddingBottom: "15px",
                  }}
                >
                  <ThemeIndicator sx={{ backgroundColor: "#424242" }} />
                  <Box
                    sx={{
                      backgroundColor: "#424242",
                      height: "15px",
                      flexGrow: 1,
                      borderRadius: "5px",
                    }}
                  ></Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: "5px",
                    paddingBottom: "15px",
                  }}
                >
                  <ThemeIndicator sx={{ backgroundColor: "#424242" }} />
                  <Box
                    sx={{
                      backgroundColor: "#424242",
                      height: "15px",
                      flexGrow: 1,
                      borderRadius: "5px",
                    }}
                  ></Box>
                </Box>
                <ActiveIndicator />
              </DarkThemeOption>
              <Typography
                color="textPrimary"
                sx={{ padding: "5px", textAlign: "center" }}
              >
                Dark Mode
              </Typography>
            </Box>

            <Box>
              <SystemThemeOption
                mode={mode}
                onClick={() => handleThemeChange("system")}
              >
                <Box
                  sx={{
                    width: "50%",
                    backgroundColor: "#FFF",
                    borderRadius: "20px 0px 0px 20px",
                    padding: "20px",
                    gap: "10px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      height: "15px",
                      width: "60px",
                      backgroundColor: "#D3D6D7",
                      borderRadius: "5px",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      height: "15px",
                      width: "60px",
                      backgroundColor: "#D3D6D7",
                      borderRadius: "5px",
                    }}
                  ></Box>
                  <SystemIndicator />
                </Box>

                <Box
                  sx={{
                    width: "50%",
                    backgroundColor: "#303030",
                    borderRadius: "0px 20px 20px 0px",
                    padding: "20px",
                    gap: "10px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      height: "15px",
                      width: "60px",
                      backgroundColor: "#424242",
                      borderRadius: "5px",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      height: "15px",
                      width: "60px",
                      backgroundColor: "#424242",
                      borderRadius: "5px",
                    }}
                  ></Box>
                  <SystemIndicator />
                </Box>
              </SystemThemeOption>
              <Typography
                color="textPrimary"
                sx={{ padding: "5px", textAlign: "center" }}
              >
                System Default
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
