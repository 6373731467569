import { Avatar, Box, styled } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";

const drawerWidth = 300;
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "isSmallScreen",
})<{
  open?: boolean;
  isSmallScreen?: boolean;
}>(({ theme, open, isSmallScreen }) => ({
  width: "100%",
  height: "100vh",
  overflow: "hidden",
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: isSmallScreen ? 0 : `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

export const AvatarImg = styled(Avatar)`
  background-color: #41c175;
  width: 50px;
  height: 50px;

  @media (max-width: 768px) {
    width: 34px;
    height: 34px;
  }
`;

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  padding: "29px 75px 0px 35px",
  variants: [
    {
      props: ({ open }) => open,
      style: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(["margin", "width"], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
  "@media(max-width:768px)": {
    padding: "29px 20px 0px 20px",
  },
}));

export const ProfileImage = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  .profile-text {
    font-weight: 700;
    font-size: 22px;
    font-family: "ProximaNova" !important;
    line-height: 26px;
    letter-spacing: 0.66px;
    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
`;
export const DrawerHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export const NotificationWrapper = styled(Box)`
  position: relative;
  padding: 13px 0px;
  display: flex;
  justify-content: space-between;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0px;
    right: 0px;
    border-bottom: 1px solid rgba(126, 127, 134, 1);
  }

  &:hover {
    background-color: rgba(97, 103, 255, 0.18);
  }
`;
