// import { useState, useEffect } from "react";
// import axios from "axios";
// import moment from "moment";
// import { useNavigate } from "react-router-dom";
// import {
//   Card,
//   CardContent,
//   CircularProgress,
//   TableBody,
//   TableHead,
//   TablePagination,
//   TableRow,
// } from "@mui/material";

// import getUser from "../../Utils/getUser";

// import {
//   PreviousRecomandationBox,
//   PreviousRecomandationText,
//   NextButton,
//   StyledTableContainer,
//   StyledTable,
//   StyledTableHeader,
//   StyledTableCell,
// } from "./styles";

// const Notifications = () => {
//   const navigate = useNavigate();
//   const [notis, setNotis] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [madeOffer, setMadeOffer] = useState(false);
//   const [foundPage, setFoundPage] = useState(0);
//   const [foundRowsPerPage, setFoundRowsPerPage] = useState(10);
//   const [foundPaginatedData, setFoundPaginatedData] = useState<any[]>([]);
//   const [orderBy, setOrderBy] = useState("seen");
//   const [order, setOrder] = useState<"asc" | "desc">("desc");

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(
//           process.env.REACT_APP_API_BASE_URL + "user/get_noti",
//           { params: { userID: getUser() } }
//         );
//         setNotis(response.data.notifications);
//         // setMadeOffer(response.data.offerFound);
//         setIsLoading(false);
//       } catch (error) {
//         console.error("Error grabbing listing:", error);
//       }
//     };

//     fetchData();
//   }, []);

//   useEffect(() => {
//     const startFoundIndex = foundPage * foundRowsPerPage;
//     const endFoundIndex = startFoundIndex + foundRowsPerPage;
//     setFoundPaginatedData(notis.slice(startFoundIndex, endFoundIndex));
//   }, [notis, foundPage, foundRowsPerPage]);

//   const handleFoundChangePage = (
//     event: React.MouseEvent<HTMLButtonElement> | null,
//     newPage: number
//   ) => {
//     setFoundPage(newPage);
//   };

//   const handleFoundChangeRowsPerPage = (
//     event: React.ChangeEvent<HTMLInputElement>
//   ) => {
//     setFoundRowsPerPage(parseInt(event.target.value, 10));
//     setFoundPage(0);
//   };

//   const handleSeeOffer = (id: string) => {
//     navigate("/review-offer?o=" + id);
//   };

//   const sortedData = notis.sort((a, b) => {
//     if (orderBy) {
//       if (order === "asc") {
//         return a[orderBy] < b[orderBy] ? -1 : 1;
//       }
//       return a[orderBy] > b[orderBy] ? -1 : 1;
//     }
//     return 0;
//   });

//   const showMessage = (message: string) => {
//     if (message.length <= 0) {
//       return <span> </span>;
//     } else {
//       return <span> {message} </span>;
//     }
//   };

//   const showAction = (noti: any) => {
//     console.log(noti);
//     if (noti.offerId) {
//       return (
//         <NextButton onClick={() => handleSeeOffer(noti.offerId)}>
//           {" "}
//           See Full Offer{" "}
//         </NextButton>
//       );
//     } else {
//       return <NextButton disabled> No action </NextButton>;
//     }
//   };

//   return (
//     <>
//       <PreviousRecomandationBox>
//         <PreviousRecomandationText>Notifications </PreviousRecomandationText>
//         {isLoading ? (
//           <Card className="mt-2 p-0" sx={{ boxShadow: 0 }}>
//             <CardContent className="pt-1 d-flex justify-content-center">
//               <CircularProgress sx={{ color: "#41c175" }} />
//             </CardContent>
//           </Card>
//         ) : (
//           <>
//             <StyledTableContainer>
//               <StyledTable aria-label="simple table">
//                 <TableHead>
//                   <TableRow>
//                     <StyledTableHeader align="center">
//                       Notification
//                     </StyledTableHeader>
//                     <StyledTableHeader
//                       align="center"
//                       sortDirection={orderBy === "dateStart" ? order : false}
//                     >
//                       Date
//                     </StyledTableHeader>
//                     <StyledTableHeader align="center">
//                       Actions
//                     </StyledTableHeader>
//                     {/**
//                   <StyledTableHeader align="center" >
//                     Listings
//                   </StyledTableHeader>
//                   */}
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   {sortedData
//                     .slice(
//                       foundPage * foundRowsPerPage,
//                       foundPage * foundRowsPerPage + foundRowsPerPage
//                     )
//                     ?.map((noti: any, index: Number) => (
//                       <TableRow>
//                         <StyledTableCell align="center">
//                           {showMessage(noti.message)}
//                         </StyledTableCell>
//                         <StyledTableCell align="center">
//                           {moment(noti.date).format("MM/DD/YYYY")}
//                         </StyledTableCell>
//                         <StyledTableCell align="center">
//                           {showAction(noti)}
//                         </StyledTableCell>
//                       </TableRow>
//                     ))}
//                 </TableBody>
//               </StyledTable>
//             </StyledTableContainer>
//             <TablePagination
//               rowsPerPageOptions={[5, 10, 25]}
//               component="div"
//               count={notis.length}
//               rowsPerPage={foundRowsPerPage}
//               page={foundPage}
//               onPageChange={handleFoundChangePage}
//               onRowsPerPageChange={handleFoundChangeRowsPerPage}
//               sx={{
//                 "& .MuiTablePagination-displayedRows": {
//                   marginBottom: "0rem",
//                 },
//                 "& .MuiTablePagination-selectLabel": {
//                   marginBottom: "0rem",
//                 },
//               }}
//             />
//           </>
//         )}
//       </PreviousRecomandationBox>
//     </>
//   );
// };
// export default Notifications;

import { useEffect } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress, Typography } from "@mui/material";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

import { useLazyGetNotification } from "../../Redux/api/User";

import getUser from "../../Utils/getUser";

import {
  NoNotificationContainer,
  NotificationCard,
  StyledNotificationText,
  StyledOfferButton,
} from "./styles";
import noNotifications from "../../assets/images/Notification.svg";

const Notifications = () => {
  const navigate = useNavigate();
  const [
    getNotification,
    {
      data: { data: { notifications: notificationData = [] } = {} } = {},
      isLoading,
    },
  ]: any = useLazyGetNotification();

  const handleSeeOffer = (id: string) => {
    navigate("/review-offer?o=" + id);
  };

  useEffect(() => {
    getNotification(getUser());
  }, []);

  return (
    <>
      <Box
        sx={{
          width: "80%",
          margin: "0 auto",
          height: "100%",
        }}
      >
        <StyledNotificationText color="textPrimary">
          Notifications
        </StyledNotificationText>

        {isLoading ? (
          <Box
            sx={{
              height: "calc(100% - 55px)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress color="primary" />
          </Box>
        ) : notificationData.length ? (
          <Box sx={{ width: "80%", margin: "0 auto" }}>
            {notificationData.map((notification: any, i: Number) => {
              return (
                <NotificationCard>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      pb: "5px",
                    }}
                  >
                    <Typography
                      color="textPrimary"
                      sx={{
                        fontSize: "18px",
                        pb: "5px",
                        fontFamily: "ProximaNova !important",
                      }}
                    >
                      {notification?.message}
                    </Typography>
                    <Typography
                      sx={{
                        marginLeft: "auto",
                        whiteSpace: "nowrap",
                        fontFamily: "ProximaNova !important",
                      }}
                    >
                      {moment(
                        new Date(notification?.date),
                        "YYYYMMDD"
                      ).fromNow()}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    {notification?.offerId && (
                      <StyledOfferButton
                        variant="outlined"
                        endIcon={<ArrowOutwardIcon />}
                        onClick={() => handleSeeOffer(notification?.offerId)}
                      >
                        See Full Offer
                      </StyledOfferButton>
                    )}
                  </Box>
                </NotificationCard>
              );
            })}
          </Box>
        ) : (
          <NoNotificationContainer>
            <img
              src={noNotifications}
              alt="No Notifications Bell"
              style={{ padding: "10px 0px 30px 0px", width: "200px" }}
            />
            <Typography
              color="textPrimary"
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
                paddingBottom: "20px",
                fontFamily: "ProximaNova !important",
              }}
            >
              No Notifications Yet!
            </Typography>
            <Typography
              color="textPrimary"
              sx={{ fontSize: "18px", fontFamily: "ProximaNova !important" }}
            >
              "We'll notify you when something arrives!"
            </Typography>
          </NoNotificationContainer>
        )}
      </Box>
    </>
  );
};

export default Notifications;
