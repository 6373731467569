import { createTheme } from "@mui/material/styles";

import { getPalette } from "./themes";

export const getCustomeTheme = (showMode: "light" | "dark") => {
  const palette = getPalette(showMode);

  return createTheme({
    components: {
      MuiTable: {
        styleOverrides: {
          root: {
            backgroundColor: palette.background.paper,
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            backgroundColor: palette.background.default,
          },
          head: {
            backgroundColor: palette.background.body,
            color: palette.text.primary,
          },
        },
      },
    },
    palette,
  });
};
