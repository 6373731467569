import React, { useRef, useState, useEffect } from "react";
import aws from "aws-sdk";
import axios from "axios";
import { toast } from "react-toastify";
import { Alert, Box, IconButton, Typography } from "@mui/material";
import Form from "react-bootstrap/Form";
import CloseIcon from "@mui/icons-material/Close";

import {
  FileUploadButton,
  FileUploadHeader,
  FileUploadBox,
  AlertBox,
  UploadedFile,
} from "./styles";
import "../../assets/styling/style.css";

const bucketName = "sotira-excess-files-test";
const region = "us-west-1";
const accessKeyId = "AKIA5NU6SDDXI5HUA7PT";
const secretAccessKey = "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";

export default function UPopup() {
  const queryParameters = new URLSearchParams(window.location.search);
  const code = queryParameters.get("c");

  const [file, setFile] = useState<File | null>(null);
  const [upload, setUpload] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const imageInputRef = useRef<HTMLInputElement>(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [location, setLocation] = useState("");
  const [priority, setPriority] = useState("");
  const [fileExtension, setFileExtension] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  //email List Items

  const date = new Date();

  //Get Month/Year for updated Calendy Link
  const year = String(date.getFullYear());
  var month = String(date.getMonth() + 1);
  if (date.getMonth() + 1 < 10) {
    month = "0" + String(month);
  }

  useEffect(() => {
    setUpload(
      email.length > 0 &&
        phone.length > 0 &&
        location.length > 0 &&
        priority.length > 0 &&
        !!file &&
        fileExtension
    );
  }, [
    email.length,
    phone.length,
    location.length,
    priority.length,
    file,
    fileExtension
  ]);

  const handleFileChangeRaw = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const uploadedFile = e.target.files[0];
      const fileExtension = uploadedFile.name.split(".").pop()?.toLowerCase();
      if (fileExtension === "csv") {
        setFile(uploadedFile);
        setFileExtension(true);
      } else if (fileExtension === "xlsx" || fileExtension === "xls") {
        setFile(uploadedFile);
        setFileExtension(true);
      } else {
        setFileExtension(false);
        toast.error("Unsupported file format.", {
          position: "top-right",
          autoClose: 1500,
        });
      }
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      // Convert FileList to an array and update state
      setSelectedFiles((prev) => [...prev, ...Array.from(files)]);
      console.log(selectedFiles);
    }
  };

  const handleFileinput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const s3 = new aws.S3({
    region,
    accessKeyId,
    secretAccessKey,
  });

  const handleUpload = async () => {
    if (!file) {
      console.error("No file selected.");
      return;
    }

    const fileKey = `publicUpload/${email}/${date}/${file.name}`;

    const params = {
      Key: fileKey,
      Bucket: bucketName,
      Body: file,
      ContentType: file.type,
    };

    try {
      await s3.putObject(params).promise();
    } catch (error: any) {
      console.error("Error uploading file:", error);
    }

    var picFilekey: any[] = [];

    if(selectedFiles.length > 0) {
    
    try {
      await Promise.all(
        selectedFiles.map(async (file) => {
          const file_key = `publicUpload/${email}/${date}/${file.name}`;

          picFilekey.push(file_key);


          const params = {
            Key: file_key,
            Bucket: bucketName,
            Body: file, // Using the actual file here
            ContentType: file.type, // Adjust the content type if necessary
          };

          // Upload to S3
          await s3.putObject(params).promise();

        })
      );
      } catch (error: any) {
        console.error("Error uploading files:", error);
      }
    }

    var params_listing = {
      name: name,
      email: email,
      phone: phone,
      location: location,
      priority: priority,
      fileId: fileKey,
      code: code,
      logged: false,
      imageFileKeys: picFilekey,
    };

    try {
      await axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "offer/full_upload",
          params_listing
        )
        .then((response) => {
          toast.success("Inventory Uploaded", {
            position: "top-right",
            autoClose: 1500,
          });
          setConfirm(true);
        });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      console.error("Error Submitting Order:", error);
    }
  };

  const handleImageInput = () => {
    if (imageInputRef.current) {
      imageInputRef.current.click();
    }
  };

  const handleRemoveImage = (image: File) => {
    setSelectedFiles((prevImages) =>
      prevImages.filter((file) => file.name !== image.name)
    );
  };

  return (
    <>
      <Form onSubmit={(e) => e.preventDefault()}>
        {confirm ? (
          <div>
            <FileUploadHeader>Confirm Upload</FileUploadHeader>
            <AlertBox>
              <Alert severity="success" className="alert-text">
                Thanks for uploading! We will be in touch ASAP. In the meantime,
                please reach out to{" "}
                <a href="mailto:support@sotira.co">support@sotira.co</a> with
                any questions.
              </Alert>
            </AlertBox>
          </div>
        ) : (
          <div>
            <div>
              {" "}
              <FileUploadHeader>Upload Data</FileUploadHeader>
              <div
                style={{ border: "1px solid #CCC", marginBottom: "20px" }}
              ></div>
              <div> Upload data sheet here in .csv or .xlsx format </div>
              <br />
              <Form.Group className="form-field">
                <Form.Label className="title">Email *</Form.Label>
                <Form.Control
                  autoFocus
                  type="email"
                  placeholder="Enter email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              <br />
              <Form.Group className="form-field">
                <Form.Label className="title">Name *</Form.Label>
                <Form.Control
                  autoFocus
                  type="text"
                  placeholder="Enter your name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>
              <br />
              <Form.Group className="form-field">
                <Form.Label className="title">Phone Number *</Form.Label>
                <Form.Control
                  autoFocus
                  type="text"
                  placeholder="Phone Number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Form.Group>
              <br />
              <Form.Group className="form-field">
                <Form.Label className="title">Location *</Form.Label>
                <Form.Control
                  autoFocus
                  type="text"
                  placeholder="City, State"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                />
              </Form.Group>
              <br />
              <Form.Group className="form-field">
                <Form.Label className="title">
                  When do you need to this cleared by? *
                </Form.Label>
                <Form.Control
                  autoFocus
                  type="text"
                  placeholder="3 weeks"
                  value={priority}
                  onChange={(e) => setPriority(e.target.value)}
                />
              </Form.Group>
              <br />
              <Typography>Upload Data Sheet (CSV or Excel) *:</Typography>
              <FileUploadBox onClick={handleFileinput}>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChangeRaw}
                  disabled={file !== null}
                />
                {!file ? (
                  <p style={{ padding: "20px", marginBottom: "0px" }}>
                    Drop file here or click to upload
                  </p>
                ) : (
                  <>
                    <UploadedFile>{file.name}</UploadedFile>
                  </>
                )}
              </FileUploadBox>
              <Typography>Upload Pictures:</Typography>
              <FileUploadBox onClick={handleImageInput}>
                <input
                  ref={imageInputRef} // Attach ref to the input
                  accept="image/png, image/jpeg"
                  style={{ display: "none" }}
                  multiple
                  type="file"
                  onChange={handleFileChange}
                />

                <Typography
                  color="textPrimary"
                  sx={{ padding: "20px", fontFamily: "ProximaNova" }}
                >
                  Drop file here or click to upload
                </Typography>

                {/* Display selected images */}
              </FileUploadBox>
              <Box
                mt={2}
                display="flex"
                flexWrap="wrap"
                justifyContent="center"
              >
                {selectedFiles?.map((image: any, index: any) => (
                  <Box key={index} sx={{ margin: 1, position: "relative" }}>
                    <img
                      src={URL.createObjectURL(image)}
                      alt={`uploaded-${index}`}
                      style={{ width: 100, height: 100, objectFit: "cover" }}
                    />

                    <IconButton
                      onClick={() => handleRemoveImage(image)}
                      sx={{
                        position: "absolute",
                        top: "-9px",
                        right: "-9px",
                        color: "red",
                        borderRadius: "50%",
                        padding: "0px",
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                ))}
              </Box>
              <AlertBox>
                <Alert severity="error" className="alert-text">
                  Trouble uploading your inventory data? Email your file to{" "}
                  <a href="mailto:support@sotira.co">support@sotira.co</a> or{" "}
                  <a
                    href={
                      "https://calendly.com/amritabhasin/15min?back=1&month=" +
                      year +
                      "-" +
                      month
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    schedule a meeting at this link
                  </a>{" "}
                  and we will upload it for you.
                </Alert>
              </AlertBox>
            </div>

            <div>
              <FileUploadButton onClick={handleUpload} disabled={!upload}>
                Upload Inventory Now
              </FileUploadButton>
            </div>
          </div>
        )}
      </Form>
    </>
  );
}
