import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";

import {
  BoldText,
  ProgressDiv,
  StyledTable,
  StyledTableContainer,
  StyledTableHeader,
} from "./styles";

interface ListingItem {
  _id: string;
  itemName: string;
  upc: string;
  sellPrice: number;
  costPrice: number;
  quantity: number;
  location: string;
  expDate: string;
  endDate: string;
  sold: number;
  status: string;
  buyer: string;
  seller: string;
}

interface DataTableProps {
  offerData: ListingItem[];
  bulk: boolean;
  isLoading: boolean;
  onUpdateCost: (itemId: string, cost: number) => void; // Function to update cost in parent
  onUpdateQuantity: (itemId: string, quantity: number) => void; // Function to update quantity in parent
}

const DataTable: React.FC<DataTableProps> = ({
  offerData,
  bulk,
  isLoading,
  onUpdateCost,
  onUpdateQuantity,
}) => {
  const [foundPage, setFoundPage] = useState(0);
  const [foundRowsPerPage, setFoundRowsPerPage] = useState(5);
  const [foundPaginatedData, setFoundPaginatedData] = useState<ListingItem[]>(
    []
  );
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<keyof ListingItem>("expDate");

  useEffect(() => {
    const startFoundIndex = foundPage * foundRowsPerPage;
    const endFoundIndex = startFoundIndex + foundRowsPerPage;
    setFoundPaginatedData(offerData.slice(startFoundIndex, endFoundIndex));
  }, [offerData, foundPage, foundRowsPerPage]);

  const handleFoundChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setFoundPage(newPage);
  };

  const handleFoundChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFoundRowsPerPage(parseInt(event.target.value, 10));
    setFoundPage(0);
  };

  const handleRequestSort =
    (property: keyof ListingItem) => (event: React.MouseEvent<unknown>) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };

  const handleCostChange = (
    itemId: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newCost = parseFloat(event.target.value);
    onUpdateCost(itemId, newCost);
  };

  const handleQuantityChange = (
    itemId: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newQuantity = parseInt(event.target.value, 10);
    onUpdateQuantity(itemId, newQuantity);
  };

  function getFormattedDate(date: any) {
    //console.log(date);
    var year = date.getFullYear();
    //console.log(year);
    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : "0" + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : "0" + day;

    return month + "/" + day + "/" + year;
  }

  const sortedData = offerData.sort((a, b) => {
    if (orderBy) {
      if (order === "asc") {
        return a[orderBy] < b[orderBy] ? -1 : 1;
      }
      return a[orderBy] > b[orderBy] ? -1 : 1;
    }
    return 0;
  });

  return (
    <div>
      {isLoading ? (
        <ProgressDiv>
          <CircularProgress color="primary" />
        </ProgressDiv>
      ) : (
        <div>
          <StyledTableContainer>
            <StyledTable aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableHeader
                    align="center"
                    sortDirection={orderBy === "upc" ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === "upc"}
                      direction={orderBy === "upc" ? order : "asc"}
                      onClick={handleRequestSort("upc")}
                    >
                      <BoldText>UPC</BoldText>
                    </TableSortLabel>
                  </StyledTableHeader>
                  <StyledTableHeader align="center">
                    <BoldText>Item Name</BoldText>
                  </StyledTableHeader>
                  <StyledTableHeader
                    align="center"
                    sortDirection={orderBy === "quantity" ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === "quantity"}
                      direction={orderBy === "quantity" ? order : "asc"}
                      onClick={handleRequestSort("quantity")}
                    >
                      <BoldText>Quantity</BoldText>
                    </TableSortLabel>
                  </StyledTableHeader>
                  <StyledTableHeader
                    align="center"
                    sortDirection={orderBy === "sellPrice" ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === "sellPrice"}
                      direction={orderBy === "sellPrice" ? order : "asc"}
                      onClick={handleRequestSort("sellPrice")}
                    >
                      <BoldText>Sell Price</BoldText>
                    </TableSortLabel>
                  </StyledTableHeader>
                  <StyledTableHeader
                    align="center"
                    sortDirection={orderBy === "expDate" ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === "expDate"}
                      direction={orderBy === "expDate" ? order : "asc"}
                      onClick={handleRequestSort("expDate")}
                    >
                      <BoldText>Exp Date</BoldText>
                    </TableSortLabel>
                  </StyledTableHeader>
                  <StyledTableHeader align="center">
                    <BoldText>Location (ZIP)</BoldText>
                  </StyledTableHeader>
                  {!bulk && (
                    <>
                      <StyledTableHeader align="center">
                        <BoldText>Request Cost</BoldText>
                      </StyledTableHeader>
                      <StyledTableHeader align="center">
                        <BoldText>Request Quantity</BoldText>
                      </StyledTableHeader>
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedData
                  .slice(
                    foundPage * foundRowsPerPage,
                    foundPage * foundRowsPerPage + foundRowsPerPage
                  )
                  .map((item, index) => (
                    <TableRow key={item._id}>
                      <TableCell>{item.upc}</TableCell>
                      <TableCell>{item.itemName}</TableCell>
                      <TableCell sx={{ color: "none" }}>
                        {item.quantity}
                      </TableCell>
                      <TableCell sx={{ color: "rgb(72, 183, 167) !important" }}>
                        ${item.sellPrice}
                      </TableCell>
                      <TableCell sx={{ color: "#ff6767 !important" }}>
                        {item.expDate
                          ? getFormattedDate(new Date(item.expDate))
                          : "N/A"}
                      </TableCell>
                      <TableCell>
                        {item.location === "undefined" ? "N/A" : item.location}
                      </TableCell>
                      {!bulk && (
                        <>
                          <TableCell>
                            <input
                              type="number"
                              step="0.01"
                              min="0"
                              style={{ width: "100%", padding: "5px" }}
                              onChange={(e) => handleCostChange(item._id, e)}
                            />
                          </TableCell>
                          <TableCell>
                            <input
                              type="number"
                              min="0"
                              style={{ width: "100%", padding: "5px" }}
                              onChange={(e) =>
                                handleQuantityChange(item._id, e)
                              }
                            />
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </StyledTable>
          </StyledTableContainer>

          <TablePagination
            component="div"
            count={offerData.length}
            rowsPerPage={foundRowsPerPage}
            page={foundPage}
            onPageChange={handleFoundChangePage}
            onRowsPerPageChange={handleFoundChangeRowsPerPage}
          />
        </div>
      )}
    </div>
  );
};

export default DataTable;
