import { useNavigate } from "react-router-dom";

import DataTable from "./DataTable";

import { storageCostRoute } from "../../Routes/constants";

import {
  DataPreviewBox,
  DataPreviewText,
  PreviewBox,
  ContentText,
  Datatable,
  NextButton,
} from "./styles";

const DataPreview = () => {
  const navigate = useNavigate();

  const handlestorageCost = () => {
    navigate(storageCostRoute);
  };
  return (
    <>
      <DataPreviewBox>
        <PreviewBox>
          <DataPreviewText color="textPrimary">Data Preview</DataPreviewText>
          <ContentText color="textPrimary">
            We will process this data and the answers to the questions to
            customize the platform for you.
          </ContentText>
        </PreviewBox>
        <Datatable>
          <DataTable />
          <NextButton onClick={handlestorageCost}>Next</NextButton>
        </Datatable>
      </DataPreviewBox>
    </>
  );
};

export default DataPreview;
