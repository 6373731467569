import { TableCell, Typography, styled } from "@mui/material";

export const DataPreviewBox = styled("div")`
  max-width: 900px;
  width: 100%;
  margin: auto;
  height: 100%;
  min-height: 740px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 1280px) and (min-width: 768px) {
    min-height: 620px;
  }
`;

export const DataPreviewText = styled(Typography)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 35px;
  font-style: normal;
  font-family: "ProximaNova" !important;
  font-weight: 700;
  line-height: 124%;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

export const PreviewBox = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

export const ContentText = styled(Typography)`
  font-size: 22px;
  font-family: "ProximaNova-Medium" !important;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const Datatable = styled("div")`
  gap: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 75px;
  max-width: 692px;
  width: 100%;
  @media (max-width: 1280px) and (min-width: 768px) {
    gap: 50px;
    margin-top: 50px;
  }
  @media (max-width: 393px) {
    gap: 45px;
    margin-top: 44px;
  }
`;

export const NextButton = styled("button")`
  background-color: #48b7a7;
  color: white;
  font-weight: 700;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: 1px solid;
  padding: 15px 25px;
  border-radius: 5px;
  font-size: 22px;
  font-family: "ProximaNova !important";
  gap: 10px;
  max-width: 96px;
  width: 100%;
  height: 55px;
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const StyledTableHeader = styled(TableCell)({
  fontSize: "14px",
  fontWeight: 700,
  fontFamily: "ProximaNova, sans-serif",
  borderRight: "1px solid #7C7C7C",
  borderTop: "1px solid #7C7C7C",
  borderBottom: "none",
  "&:last-child": {
    borderRight: "none",
  },
});

export const StyledTableCell = styled(TableCell)({
  fontSize: "14px",
  fontWeight: 400,
  fontFamily: "ProximaNova, sans-serif",
  borderRight: "1px solid #7C7C7C",
  borderTop: "1px solid #7C7C7C",
  borderBottom: "none",
  "&:last-child": {
    borderRight: "none",
  },
});
