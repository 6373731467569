import { useEffect, useMemo, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ToastContainer } from "react-toastify";

import { authUser } from "./Redux/auth/reducer";

import Route from "./Routes";

import { getCustomeTheme } from "./Utils/getCustomeTheme";

import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";

interface authuser {
  authUser: {
    isLoggedIn: boolean;
  };
}

const App = () => {
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state: authuser) => state.authUser);
  const [prefersDarkMode, setPrefersDarkMode] = useState<boolean>(false);
  const [showMode, setShowMode] = useState<any>(
    localStorage.getItem("themeMode") || "light"
  );
  const storageTheme = localStorage.getItem("themeMode");

  const theme = useMemo(
    () =>
      getCustomeTheme(
        storageTheme === "system"
          ? prefersDarkMode
            ? "dark"
            : "light"
          : showMode
      ),
    [showMode, prefersDarkMode]
  );

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      jwtDecode(token)
        ? dispatch(authUser({ isLoggedIn: true }))
        : dispatch(authUser({ isLoggedIn: false }));
    } else {
      dispatch(authUser({ isLoggedIn: false }));
    }
  }, [isLoggedIn, dispatch]);

  // Listen for changes in localStorage for theme mode
  useEffect(() => {
    const handleStorageChange = () => {
      const newMode = localStorage.getItem("themeMode") || "light";
      setShowMode(newMode);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  // Listen to system theme changes using matchMedia API
  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

    // Update prefersDarkMode based on system theme
    const handleSystemThemeChange = (e: MediaQueryListEvent) => {
      setPrefersDarkMode(e.matches); // true if system is in dark mode
    };

    // Initialize the current system theme on mount
    setPrefersDarkMode(mediaQuery.matches);

    // Add listener to update on theme change
    mediaQuery.addEventListener("change", handleSystemThemeChange);

    // Cleanup listener on component unmount
    return () => {
      mediaQuery.removeEventListener("change", handleSystemThemeChange);
    };
  }, []);

  // Adjust theme if "system" mode is selected
  useEffect(() => {
    if (storageTheme === "system") {
      setShowMode(prefersDarkMode ? "dark" : "light");
    }
  }, [prefersDarkMode]);

  return (
    <ThemeProvider theme={theme}>
      <GoogleOAuthProvider clientId="YOUR_GOOGLE_CLIENT_ID_HERE">
        <div className="App">
          <ToastContainer autoClose={2000} limit={5} />
          <Route />
        </div>
      </GoogleOAuthProvider>
    </ThemeProvider>
  );
};

export default App;
