import styled from "@emotion/styled";
import { Card, Typography } from "@mui/material";

export const OptionsBox = styled("div")`
  max-width: 1240px;
  width: 100%;
  margin: auto;
  height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 899px) {
    height: max-content;
  }
`;

export const Offloadingheader = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  .option-text {
    padding-top: 13px;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: "ProximaNova-Medium" !important;
    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
`;

export const OffloadingOptionsText = styled(Typography)`
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  font-family: "ProximaNova" !important;
  line-height: normal;
  margin-top: 47px;
  @media (max-width: 768px) {
    font-size: 22px;
  }
  @media (max-width: 899px) {
    margin-top: 86px;
  }
`;
export const OptionsFrameBox = styled("div")`
  width: 100%;
  display: flex;
  margin-top: 2%;
`;

export const OptionsFrame = styled(Card)`
  margin-top: 10%;
  width: 100%;
  max-width: 514px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  @media (max-width: 899px) {
    margin: 0 auto;
  }
`;
export const OptionsImages = styled("div")`
  min-height: 88px;
  display: flex;
  justify-content: flex-end;
`;

export const OptionsFrameHeader = styled(Typography)`
  font-size: 20px;
  font-weight: 700;
  font-family: "ProximaNova" !important;
  min-height: 50px;
  font-style: normal;
  line-height: normal;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const OptionsFrameContent = styled("div")`
  text-align: center;
  color: #848484;
  font-size: 16px;
  font-weight: 400;
  font-family: "ProximaNova-Medium" !important;
  min-height: 60px;
  height: 100%;
  @media (max-width: 768px) {
    font-size: 14px;
    padding-bottom: 10px;
  }
`;
export const EstimatedText = styled(Typography)`
  font-size: 20px;
  font-family: "ProximaNova" !important;
  line-height: normal;
  font-weight: 700;
  span {
    color: #41c175;
  }
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const StartButton = styled("button")`
  background-color: #48b7a7;
  color: #fff;
  font-weight: 700;
  line-height: 1.5;
  justify-content: flex-end;
  align-items: center;
  text-decoration: none;
  border: 1px solid;
  padding: 4px 25px;
  border-radius: 5px;
  font-size: 16px;
  font-family: "ProximaNova" !important;
  gap: 10px;
  max-width: 296px;
  width: 100%;
  max-height: 36px;
  height: 100%;
  margin-top: 15%;
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const AdditionalOptionButton = styled("button")`
  background-color: #48b7a7;
  color: white;
  font-weight: 700;
  line-height: 1.5;
  justify-content: flex-end;
  align-items: center;
  text-decoration: none;
  border: 1px solid;
  padding: 8px 25px;
  border-radius: 5px;
  font-size: 22px;
  font-family: "ProximaNova" !important;
  gap: 10px;
  max-width: 550px;
  width: 100%;
  max-height: 55px;
  height: 100%;
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const AnalysisButton = styled("button")`
  background-color: #48b7a7;
  color: white;
  font-weight: 700;
  line-height: 1.5;
  justify-content: flex-end;
  align-items: center;
  text-decoration: none;
  border: 1px solid;
  padding: 8px 25px;
  border-radius: 5px;
  font-size: 22px;
  font-family: "ProximaNova" !important;
  gap: 10px;
  max-width: 550px;
  width: 100%;
  max-height: 55px;
  height: 100%;
  margin-top: 1.09%;
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;
