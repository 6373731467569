interface CustomTheme {
  mode: "light" | "dark";
  primary: { main: string };
  background: { default: string; paper: string; body: string };
  text: { primary: string; border: string };
  divider: string;
  action: {
    active: string;
    hover: string;
    selected: string;
    disabled: string;
    disabledBackground: string;
    selectedBackground: string;
  };
  error: { main: string };
  warning: { main: string };
  info: { main: string };
  success: { main: string };
  common: {
    white: string;
    black: string;
  };
}

export const getPalette = (mode: any): CustomTheme => {
  switch (mode) {
    case "light":
      return lightTheme;
    case "dark":
      return darkTheme;
    default:
      return lightTheme;
  }
};

const lightTheme: CustomTheme = {
  mode: "light",
  primary: { main: "#14c175" },
  background: { default: "#FFF", paper: "#fcfcfc", body: "#EDEDED" },
  text: { primary: "#000", border: "red" },
  divider: "rgba(0, 0, 0, 0.12)",
  action: {
    active: "#000",
    hover: "rgba(0, 0, 0, 0.08)",
    selected: "#D3D6D7",
    disabled: "rgba(0, 0, 0, 0.3)",
    disabledBackground: "rgba(0, 0, 0, 0.12)",
    selectedBackground: "#FFF",
  },
  error: { main: "#d92c36" },
  warning: { main: "#ff9800" },
  info: { main: "#2196f3" },
  success: { main: "#247d39" },
  common: {
    white: "#FFF",
    black: "#000",
  },
};

const darkTheme: CustomTheme = {
  mode: "dark",
  primary: { main: "#14c175" },
  background: { default: "#303030", paper: "#424242", body: "#424242" },
  text: { primary: "#FFF", border: "blue" },
  divider: "rgba(255, 255, 255, 0.22)",
  action: {
    active: "#FFF",
    hover: "rgba(255, 255, 255, 0.08)",
    selected: "#424242",
    disabled: "rgba(255, 255, 255, 0.3)",
    disabledBackground: "rgba(255, 255, 255, 0.22)",
    selectedBackground: "#FFF",
  },
  error: { main: "#ff7979" },
  warning: { main: "#ff9800" },
  info: { main: "#2196f3" },
  success: { main: "#247d39" },
  common: {
    white: "#FFF",
    black: "#000",
  },
};
