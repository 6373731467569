import * as yup from "yup";
import Form from "react-bootstrap/Form";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";

import { usePostForgotpasswordMutation } from "../../Redux/api/User";

import { homeRoute, RegisterRoute } from "../../Routes/constants";

import { StyleButton } from "../../Common/styles";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [forgotPassword, { isLoading: isForgotPassLoading }]: any =
    usePostForgotpasswordMutation();

  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Please enter valid email Id")
      .required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const { email } = values;

      try {
        await forgotPassword({ email });

        toast.success("Please check your mail!!");
        navigate(homeRoute);
      } catch (err) {
        toast.error("Please type correct email");
      }
    },
  });

  const { values, handleChange, handleSubmit, handleBlur, touched, errors } =
    formik;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control
              autoFocus
              type="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.email && !!errors.email}
            />
            {touched.email && errors.email && (
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Box
            sx={{
              marginTop: "15px",
            }}
          >
            <StyleButton
              type="submit"
              disabled={isForgotPassLoading}
              endIcon={
                isForgotPassLoading ? (
                  <CircularProgress size={18} color="primary" />
                ) : null
              }
            >
              Forgot Password
            </StyleButton>
          </Box>
          <Box sx={{ marginTop: "5px" }}>
            Don't have an account?{" "}
            <a
              href={RegisterRoute}
              style={{
                color: "#89D0C6",
                textDecoration: "none",
                fontWeight: "bold",
              }}
            >
              Register here
            </a>
          </Box>
        </Form>
      </Box>
    </>
  );
};
export default ForgotPassword;
