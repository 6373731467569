import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useMediaQuery,
  Box,
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Badge,
  Menu,
  Typography,
  Tabs,
  Tab,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CloseIcon from "@mui/icons-material/Close";

import { logoutUserSuccess } from "../../Redux/login/reducer";
import { useLazyGetNotification } from "../../Redux/api/User";
import { authUser } from "../../Redux/auth/reducer";

import {
  homeRoute,
  logoutRoute,
  notificationsRoute,
} from "../../Routes/constants";
import { setting, sideBarList } from "../../constant";

import getUser from "../../Utils/getUser";

import {
  AppBar,
  AvatarImg,
  ProfileImage,
  DrawerHeader,
  Main,
  NotificationWrapper,
} from "./styles";
import { StyleButton } from "../../Common/styles";

import sotiraLogo from "../../assets/images/logo.png";
import NotificationIcon from "../../assets/images/svg/Notification.svg";
import noNotifications from "../../assets/images/Notification.svg";

const drawerWidth = 300;
interface AuthLayoutProp {
  children: any;
}

const AuthLayout: React.FC<AuthLayoutProp> = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  const [open, setOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("");
  const [value, setValue] = useState(0);
  const anchorRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const [getNotification, { data: { data: notificationData = {} } = {} }]: any =
    useLazyGetNotification();

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const firstName = localStorage.getItem("FirstName");
  const lastName = localStorage.getItem("LastName");

  const handleClick = (url: any) => {
    if (url === logoutRoute) {
      dispatch(logoutUserSuccess());
      dispatch(authUser({ isLoggedIn: false }));
      navigate(homeRoute);
    } else {
      setSelectedMenu(url);
      navigate(url);
    }
  };

  useEffect(() => {
    getNotification(getUser());
  }, []);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <AppBar
          position="fixed"
          open={open}
          sx={{
            backgroundColor: theme.palette.background.default,
            backgroundImage: "none",
            boxShadow: "none",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: open ? "end" : "space-between",
            }}
          >
            {!open && (
              <Box sx={{ cursor: "pointer" }}>
                <MenuIcon
                  sx={{
                    width: "34px",
                    height: "34px",
                    color: theme.palette.text.primary,
                  }}
                  onClick={() => setOpen(!open)}
                />
                <img
                  alt="logo"
                  src={sotiraLogo}
                  className="sotira-logo"
                  onClick={() => navigate(homeRoute)}
                />
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <ProfileImage>
                <Typography color="textPrimary" className="profile-text">
                  {firstName} {lastName}
                </Typography>
                <AvatarImg>
                  {firstName?.[0]}
                  {lastName?.[0]}
                </AvatarImg>
              </ProfileImage>
              <Box
                sx={{ cursor: "pointer" }}
                ref={anchorRef}
                onClick={() => setIsOpen(true)}
              >
                <Badge badgeContent={notificationData?.unseen} color="error">
                  <img src={NotificationIcon} alt="notification" />
                </Badge>
              </Box>
            </Box>
          </Box>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant={isSmallScreen ? "temporary" : "persistent"}
          anchor="left"
          open={open}
        >
          <DrawerHeader sx={{ display: "flex", justifyContent: "flex-start" }}>
            <Box sx={{ padding: "20px" }}>
              <MenuIcon
                sx={{
                  width: "34px",
                  height: "40px",
                }}
                onClick={() => setOpen(!open)}
              />
              <img
                alt="logo"
                src={sotiraLogo}
                className="sotira-logo"
                onClick={() => navigate(homeRoute)}
              />
            </Box>
          </DrawerHeader>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              height: "100vh",
            }}
          >
            <Box>
              <List>
                {sideBarList.map(({ name, icon, url }, index) => (
                  <ListItem key={index} disablePadding>
                    <ListItemButton
                      onClick={() => handleClick(url)}
                      sx={{
                        gap: "10px",
                        backgroundColor:
                          selectedMenu === url ? "E5F4EF" : "transparent", // Conditionally change the background color
                        "&:hover": {
                          backgroundColor:
                            selectedMenu === url ? "E5F4EF" : "transparent", // Ensure hover stays consistent
                        },
                        padding: "0px 16px 10px",
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: "unset",
                        }}
                      >
                        {icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={name}
                        sx={{
                          "& span": {
                            fontSize: "20px",
                            fontWeight: 400,
                            fontFamily: "ProximaNova !important",
                          },
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
            <Box>
              <List>
                {setting.map(({ name, icon, url }, index) => (
                  <ListItem key={index} disablePadding>
                    <ListItemButton
                      onClick={() => handleClick(url)}
                      sx={{
                        gap: "10px",
                        backgroundColor:
                          selectedMenu === url ? "E5F4EF" : "transparent",
                        "&:hover": {
                          backgroundColor:
                            selectedMenu === url ? "E5F4EF" : "transparent",
                        },
                        padding: "0px 16px 10px",
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: "unset",
                        }}
                      >
                        {icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={name}
                        color="textPrimary"
                        sx={{
                          "& span": {
                            fontSize: "20px",
                            fontWeight: 400,
                            fontFamily: "ProximaNova !important",
                          },
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Box>
          <Divider />
        </Drawer>
        <Main open={open} isSmallScreen={isSmallScreen}>
          <DrawerHeader />
          <Box
            id="main"
            sx={{
              height: "calc(100vh - 64px)",
              overflow: "auto",
              padding: "24px",
              backgroundColor: theme.palette.background.default,
            }}
          >
            {children}
          </Box>
        </Main>
      </Box>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorRef.current}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          "& .MuiPaper-root": {
            width: "600px",
            maxHeight: "315px",
            padding: "15px",
          },
          "& .MuiMenu-list": {
            paddingBottom: "0px",
          },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "18px",
              fontFamily: "ProximaNova !important",
            }}
          >
            Notifications
          </Typography>
          <CloseIcon
            onClick={() => setIsOpen(false)}
            sx={{ cursor: "pointer" }}
          />
        </Box>
        <Drawer />
        {notificationData?.notifications?.length ? (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#41C175",
                    },
                  }}
                  sx={{
                    fontFamily: "ProximaNova !important",
                    ".MuiTab-root.Mui-selected": {
                      color: "#41C175",
                    },
                    ".MuiTab-root": {
                      fontFamily: "ProximaNova",
                    },
                  }}
                >
                  <Tab label="All" value={0} />
                  <Tab label="Unread" value={1} />
                </Tabs>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  color: "#41C175",
                  gap: "5px",
                }}
              >
                <DoneAllIcon />
                <Typography sx={{ fontFamily: "ProximaNova !important" }}>
                  Mark all as read
                </Typography>
              </Box>
            </Box>
            <Box>
              {notificationData?.notifications?.map(
                (notification: any, i: Number) => {
                  return (
                    <NotificationWrapper>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "15px",
                            paddingLeft: "5px",
                          }}
                        >
                          <Badge
                            color={value === 0 ? "success" : "error"}
                            variant="dot"
                          />
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontFamily: "ProximaNova !important",
                            }}
                          >
                            {notification?.message}
                          </Typography>
                        </Box>
                        <Typography
                          sx={{
                            marginLeft: "auto",
                            whiteSpace: "nowrap",
                            fontSize: "16px",
                            fontFamily: "ProximaNova !important",
                          }}
                        >
                          {moment(
                            new Date(notification?.date),
                            "YYYYMMDD"
                          ).fromNow()}
                        </Typography>
                      </Box>
                    </NotificationWrapper>
                  );
                }
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                paddingTop: "10px",
              }}
            >
              <StyleButton
                sx={{ width: "200px" }}
                onClick={() => {
                  navigate(notificationsRoute);
                  setIsOpen(false);
                }}
              >
                View all notifications
              </StyleButton>
            </Box>
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={noNotifications}
              alt="No Notifications Bell"
              style={{ width: "200px", height: "200px" }}
            />
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
                paddingBottom: "20px",
                fontFamily: "ProximaNova !important",
              }}
            >
              No Notifications Yet!
            </Typography>
          </Box>
        )}
      </Menu>
    </>
  );
};

export default AuthLayout;
