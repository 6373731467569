import { useState, useEffect } from "react";
import axios from "axios";
import CurrencyInput from "react-currency-input-field";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import CPopup from "./confirmationPop";
import APopup from "./approvalPop";
import DataTable from "./DataTable";
import DataTableRaw from "./DataTableRaw";

import getUser from "../../Utils/getUser";

import {
  NewText,
  DataPreviewBox,
  NextButton,
  SuccessButton,
  RejButton,
  ContextHeading,
  HeadingText,
  CostText,
  PrevCostText,
  ButtonBox,
  NeoDiv,
  NeoCheckDiv,
  ActionBox,
  InfoBox,
} from "./styles";

interface OfferItem {
  _id: any;
  buyer: any;
  completion: any;
  listings: any;
  dateEnd: any;
  offerAmounts: any[];
  offerDates: any[];
  seller: any;
  status: any;
  fileId: any;
  approval: any;
}

interface ListingItem {
  _id: any;
  itemName: any;
  upc: any;
  sellPrice: any;
  costPrice: any;
  quantity: any;
  location: any;
  expDate: any;
  endDate: any;
  sold: any;
  status: any;
  buyer: any;
  seller: any;
}

const ReviewOffer = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const theme = useTheme();
  const [item, setItem] = useState<OfferItem>({} as OfferItem);
  const [listingItem, setListingItem] = useState<ListingItem[]>([]);
  const [rawData, setRawData] = useState(Array<{ [key: string]: any }>);
  const [showOffers, setShowOffers] = useState(false);
  // const [madeOffer, setMadeOffer] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalA, setOpenModalA] = useState(false);
  const [makeOffer, setMakeOffer] = useState(true);
  const [offerAmt, setOfferAmt] = useState("");
  const [raw, setRaw] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const offer = queryParameters.get("o");
  const user = getUser();

  const neoState = queryParameters.get("z");
  const [neop, setNeop] = useState("");
  const [buyerEmail, setBuyerEmail] = useState("");

  var total_cost = 0;

  const currDate = new Date();
  //console.log(currDate);

  //console.log(offer);
  //console.log(time);
  //console.log(user);
  //console.log(email);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_BASE_URL + "offer/get_offer",
          { params: { offerID: offer } }
        );
        setItem(response.data.offer);
        // setMadeOffer(response.data.offerFound);

        if (response.data.offer?.sentRaw) {
          setRaw(true);
          const getData = await axios.get(
            process.env.REACT_APP_API_BASE_URL + "listing/get_raw_data",
            { params: { offerID: offer } }
          );
          setRawData(getData.data.data);
        } else {
          if (response.data.offer?.listings) {
            const newListingItems = [];
            for (const listing of response.data.offer.listings) {
              const findListing = await axios.get(
                process.env.REACT_APP_API_BASE_URL + "listing/get_listing",
                { params: { listingID: listing } }
              );
              const newListingItem = findListing.data.listing;
              newListingItem.endDate = newListingItem.endDate
                ? new Date(newListingItem.endDate)
                : new Date(currDate.getTime() + 7 * 24 * 60 * 60 * 1000);
              newListingItems.push(newListingItem);
            }

            setListingItem(newListingItems);
            setIsLoading(false);
            // for (const listing of response.data.offer.listings) {
            //   const findListing = await axios.get(process.env.REACT_APP_API_BASE_URL + 'listing/get_listing', { params: { listingID: listing } });
            //   //console.log(findListing);
            //   const newListingItem = findListing.data.listing;
            //   newListingItem.endDate = newListingItem.endDate ? new Date(newListingItem.endDate) : new Date(currDate.getTime() + 7 * 24 * 60 * 60 * 1000);
            //   //console.log(newListingItem);
            //   setListingItem(prevListingItem => [...prevListingItem, newListingItem]);
            //   //console.log(listingItem);
            // }
          }
        }
      } catch (error) {
        //console.error("Error grabbing listing:", error);
      }
    };
    fetchData();
  }, []);

  if (!raw) {
    for (let listing of listingItem) {
      //console.log(listing.quantity);
      //console.log(listing.sellPrice);
      //console.log(total_cost);
      total_cost +=
        (listing.quantity ? listing.quantity : 0) *
        (listing.sellPrice ? listing.sellPrice : listing.costPrice);
      //console.log(total_cost);
    }
  } else {
    for (let listing of rawData) {
      if (listing?.Avail) {
        console.log(listing);
        if (listing?.MFSRP) {
          total_cost += parseFloat(listing.MFSRP) * parseFloat(listing.Avail);
        }
      }
      if (listing["Total Cost"]) {
        total_cost += parseFloat(listing["Total Cost"]);
      }
    }
  }

  const handleSubmit = async (e: any) => {
    console.log("submit");
    const params = {
      buyer: item.buyer,
      offerID: offer,
      offerDate: currDate,
      registedBuyer: user ? true : false,
      seller: user,
      amount: item.offerAmounts[item.offerAmounts.length - 1]
        ? item.offerAmounts[item.offerAmounts.length - 1]
        : total_cost,
      total_cost: total_cost,
      type: "seller",
      approval: true,
    };
    try {
      await axios
        .post(process.env.REACT_APP_API_BASE_URL + "offer/update_offer", params)
        .then((response) => {
          console.log(response);
          setOpenModal(true);
        });
    } catch (error) {
      console.error("Error Submitting Order:", error);
    }
  };

  const handleSubmitApprove = async (e: any) => {
    console.log("approve");
    const params = {
      offerID: offer,
    };
    try {
      await axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "offer/approve_offer",
          params
        )
        .then((response) => {
          console.log(response);
          setOpenModalA(true);
        });
    } catch (error) {
      console.error("Error Submitting Order:", error);
    }
  };

  const handleSubmitRej = async (e: any) => {
    console.log("submit");
    const params = {
      buyer: item.buyer,
      offerID: offer,
      offerDate: currDate,
      registedBuyer: user ? true : false,
      seller: user,
      amount: item.offerAmounts[item.offerAmounts.length - 1]
        ? item.offerAmounts[item.offerAmounts.length - 1]
        : total_cost,
      total_cost: total_cost,
    };
    try {
      await axios
        .post(process.env.REACT_APP_API_BASE_URL + "offer/reject_offer", params)
        .then((response) => {
          console.log(response);
          setOpenModal(true);
        });
    } catch (error) {
      console.error("Error Submitting Order:", error);
    }
  };

  const handleClose = () => {
    setOpenModal(false);
    window.location.reload();
  };

  const handleCloseA = () => {
    setOpenModalA(false);
    window.location.reload();
  };

  const handleSubmitNeo = async (e: any) => {
    console.log("submit");
    const params = {
      buyer: item.buyer,
      offerID: offer,
      offerDate: currDate,
      registedBuyer: user ? true : false,
      seller: user,
      amount: Number(offerAmt),
      total_cost: total_cost,
      type: "seller",
      approval: true,
    };
    try {
      await axios
        .post(process.env.REACT_APP_API_BASE_URL + "offer/update_offer", params)
        .then((response) => {
          console.log(response);
          setOpenModal(true);
        });
    } catch (error) {
      console.error("Error Submitting Order:", error);
    }
  };

  const handleCheckboxChange = (e: any) => {
    setMakeOffer(e.target.checked);
  };

  const handleShowPrevious = (e: any) => {
    setShowOffers(!showOffers);
  };

  const handleNextSteps = (e: any) => {
    navigate("/review-offer-info?o=" + offer);
  };

  const showOptions = () => {
    if (item.status === "IP" || item.status === "offered") {
      console.log(item.offerAmounts);
      if (item.offerAmounts && item.offerAmounts.length % 2 === 0) {
        return (
          <NextButton onClick={handleSubmit} disabled>
            Waiting on buyer
          </NextButton>
        );
      } else if (item.offerAmounts.length % 2 === 1) {
        return (
          <ButtonBox>
            <Dialog
              open={openModal}
              onClose={handleClose}
              maxWidth="md"
              fullWidth
              PaperProps={{
                style: {
                  overflowX: "hidden",
                  maxWidth: "665px",
                  width: "100%",
                },
              }}
            >
              <DialogTitle>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 0,
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <CPopup />
              </DialogContent>
            </Dialog>

            {makeOffer ? (
              <div>
                <NeoDiv>
                  Input Offer Amount:{" "}
                  <CurrencyInput
                    id="input-example"
                    name="input-name"
                    placeholder="Please enter a number"
                    defaultValue={
                      item.offerAmounts[item.offerAmounts.length - 1]
                    }
                    decimalsLimit={2}
                    prefix="$"
                    onValueChange={(value) => setOfferAmt(value ? value : "0")}
                  />
                </NeoDiv>
                <ActionBox>
                  {offerAmt != item.offerAmounts[item.offerAmounts.length-1] ? (
                  <NextButton onClick={handleSubmitNeo}>Make Offer</NextButton>
                  ):
                  <NextButton onClick={handleSubmit}>Accept Offer</NextButton>
                  }
                <RejButton onClick={handleSubmitRej}>Reject Offer</RejButton>
                </ActionBox>
              </div>
            ) : (
              <ActionBox>
                <NextButton onClick={handleSubmit}>Accept Offer</NextButton>
                <RejButton onClick={handleSubmitRej}>Reject Offer</RejButton>
              </ActionBox>
            )}
          </ButtonBox>
        );
      } else {
        console.log("old offer");
        return (
          <NextButton onClick={handleSubmit} disabled>
            Waiting on buyer
          </NextButton>
        );
      }
    } else if (item.status === "ACPT" || item.status === "ACPTN") {
      if (item.approval) {
        return (
          <SuccessButton onClick={handleNextSteps}>
            {" "}
            Deal accepted - Input important information{" "}
          </SuccessButton>
        );
      } else {
        return (
          <div>
            <Dialog
              open={openModalA}
              onClose={handleCloseA}
              maxWidth="md"
              fullWidth
              PaperProps={{
                style: {
                  overflowX: "hidden",
                  maxWidth: "665px",
                  width: "100%",
                },
              }}
            >
              <DialogTitle>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={handleCloseA}
                  aria-label="close"
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 0,
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <APopup />
              </DialogContent>
            </Dialog>
            <ActionBox>
              <NextButton onClick={handleSubmitApprove}>
                Approve Offer
              </NextButton>
              <RejButton onClick={handleSubmitRej}>Reject Offer</RejButton>
            </ActionBox>
          </div>
        );
      }
    } else {
      return (
        <NextButton onClick={handleSubmit} disabled>
          {" "}
          Something has gone wrong - contact us at sotira@sotira.co{" "}
        </NextButton>
      );
    }
  };

  const setNeo = () => {
    return (
      <div>
        Negotiated Price
        <input
          type="number"
          value={neop}
          onChange={(e) => setNeop(e.target.value)}
          placeholder="Set Price"
          style={{
            width: "100%",
            padding: "10px",
            margin: "10px 0",
            borderRadius: "5px",
            border: "1px solid #ccc",
          }}
        />
        Buyer Email
        <input
          type="text"
          value={buyerEmail}
          onChange={(e) => setBuyerEmail(e.target.value)}
          placeholder="Set Email"
          style={{
            width: "100%",
            padding: "10px",
            margin: "10px 0",
            borderRadius: "5px",
            border: "1px solid #ccc",
          }}
        />
        <NextButton onClick={handleSubmitDeal}>Set Price</NextButton>
      </div>
    );
  };

  const handleSubmitDeal = async (e: any) => {
    console.log("approve");
    const params = {
      offerID: offer,
      price: neop,
      buyer: buyerEmail,
    };
    try {
      await axios
        .post(process.env.REACT_APP_API_BASE_URL + "offer/set_offer", params)
        .then((response) => {
          console.log(response);
          setOpenModalA(true);
        });
    } catch (error) {
      console.error("Error Submitting Order:", error);
    }
  };

  return (
    <div>
      <ContextHeading
        sx={{ backgroundColor: theme.palette.background.default }}
      >
        <HeadingText color="textPrimary"> Email/Offer </HeadingText>{" "}
      </ContextHeading>
      {raw ? (
        <DataTableRaw offerData={rawData} />
      ) : (
        <DataTable offerData={listingItem} isLoading={isLoading} />
      )}
      <InfoBox>
        <CostText color="textPrimary">
          Original Total Cost: $
          {total_cost.toLocaleString(undefined, {
            minimumFractionDigits: 2,
          })}
        </CostText>

        {item.offerAmounts && item.offerAmounts.length > 0 && (
          <div>
            {item.offerAmounts.map((amount, index) => {
              if (index === item.offerAmounts.length - 1) {
                if (item.status === "ACPT" || item.status === "ACPTN") {
                  return (
                    <CostText key={amount}>
                      Offer accepted at{" "}
                      <NewText>
                        $
                        {amount.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })}
                      </NewText>
                    </CostText>
                  );
                } else {
                  return (
                    <CostText key={amount}>
                      Current offer at{" "}
                      <NewText>
                        $
                        {amount.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })}
                      </NewText>
                    </CostText>
                  );
                }
              } else {
                // Return something (like null) if conditions don't match
                if (showOffers) {
                  return (
                    <PrevCostText key={amount}>
                      Previous offers at $
                      {amount.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })}
                    </PrevCostText>
                  );
                }
                // If no condition matches, return null to avoid no return value
                return null;
              }
            })}
          </div>
        )}

        {!showOffers && item.offerAmounts && item.offerAmounts.length > 1 && (
          <CostText onClick={handleShowPrevious}>
            {" "}
            Show Previous Offers{" "}
          </CostText>
        )}

        <DataPreviewBox>
          <div> {showOptions()} </div>
          {neoState === "yes" && <div> {setNeo()} </div>}
        </DataPreviewBox>
      </InfoBox>
    </div>
  );
};

export default ReviewOffer;
