import React, { useState, CSSProperties } from "react";
import axios from "axios";
import aws from "aws-sdk";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import {
  useCSVReader,
  lightenDarkenColor,
  formatFileSize,
} from "react-papaparse";
import { Alert, CircularProgress, Popover, Typography } from "@mui/material";

import { usePostAddExcessFile, useGetExcessId } from "../../Redux/api/User";

import { buyerSearchResultsRoute } from "../../Routes/constants";

import getUser from "../../Utils/getUser";

const GREY = "#CCC";
const GREY_LIGHT = "rgba(255, 255, 255, 0.4)";
const DEFAULT_REMOVE_HOVER_COLOR = "#A01919";
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
  DEFAULT_REMOVE_HOVER_COLOR,
  40
);
const GREY_DIM = "#686868";

const styles = {
  zone: {
    alignItems: "center",
    borderWidth: 2,
    borderStyle: "dashed",
    borderColor: GREY,
    borderRadius: 20,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    padding: 20,
  } as CSSProperties,
  file: {
    background: "linear-gradient(to bottom, #EEE, #DDD)",
    borderRadius: 20,
    display: "flex",
    height: 120,
    width: 120,
    position: "relative",
    zIndex: 10,
    flexDirection: "column",
    justifyContent: "center",
  } as CSSProperties,
  info: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingLeft: 10,
    paddingRight: 10,
  } as CSSProperties,
  size: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    marginBottom: "0.5em",
    justifyContent: "center",
    display: "flex",
  } as CSSProperties,
  name: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    fontSize: 12,
    marginBottom: "0.5em",
  } as CSSProperties,
  progressBar: {
    bottom: 14,
    position: "absolute",
    width: "100%",
    paddingLeft: 10,
    paddingRight: 10,
  } as CSSProperties,
  zoneHover: {
    borderColor: GREY_DIM,
  } as CSSProperties,
  default: {
    borderColor: GREY,
  } as CSSProperties,
  remove: {
    height: 23,
    position: "absolute",
    right: 6,
    top: 6,
    width: 23,
  } as CSSProperties,
  sheetButton: {
    backgroundColor: "#48b7a7",
    border: "1px solid #48b7a7",
  } as CSSProperties,
};

const bucketName = "sotira-excess-files-test";
const region = "us-west-1";
const accessKeyId = "AKIA5NU6SDDXI5HUA7PT";
const secretAccessKey = "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";

interface BuyerSearchChildProps {
  onUpdateFoundData: (data: any[]) => void;
  onUpdateMissingData: (data: any[]) => void;
}

const UPopup: React.FC<BuyerSearchChildProps> = ({
  onUpdateFoundData,
  onUpdateMissingData,
}) => {
  const userID = getUser();
  const navigate = useNavigate();
  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);
  const [removeHoverColor, setRemoveHoverColor] = useState(
    DEFAULT_REMOVE_HOVER_COLOR
  );
  const [file, setFile] = useState<File | null>(null);
  const [csvFileData, setCsvFileData] = useState<any[]>([]);
  const [advanceDisableButton, setAdvanceDisableButton] = useState(true);
  const [importDisableButton, setImportDisableButton] = useState(true);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const openTooltip = Boolean(anchorEl);
  const [loading, setLoading] = useState(false);
  var csvFileRecords;
  const { data: { data: { excess = [] } = {} } = {} } = useGetExcessId(userID);
  const [AddExcessFile]: any = usePostAddExcessFile();

  const latestDateObject = excess.find((excessdata: any) => {
    return !excessdata?.fileName;
  });

  const s3 = new aws.S3({
    region,
    accessKeyId,
    secretAccessKey,
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    if (!file) {
      console.error("No file selected.");
      return;
    }
    const fileKey = `${userID}/buyerSearch/${file.name}`;

    const params = {
      Key: fileKey,
      Bucket: bucketName,
      Body: file,
      ContentType: file.type,
    };
    try {
      await s3.putObject(params).promise();
      const payload = {
        userID,
        excessID: latestDateObject?._id,
        fileName: file.name,
      };

      await AddExcessFile(payload);
      const getParams = {
        userID: userID,
        upc: csvFileData,
      };
      const response = await axios.get(
        process.env.REACT_APP_API_BASE_URL + "listing/search_listings",
        { params: getParams }
      );
      const { data } = response;

      onUpdateFoundData(data.found);
      onUpdateMissingData(data.missing);

      navigate(buyerSearchResultsRoute);
    } catch (error: any) {
      console.error("Error uploading file:", error);
    }
  };

  function getCsvFileData(results: any) {
    csvFileRecords = results.data;
    const fileHeaders = csvFileRecords[0].map((head: any) =>
      String(head).toLowerCase().trim()
    );
    if (fileHeaders.length >= 2) {
      if (fileHeaders.indexOf("upc") === -1) {
        setImportDisableButton(true);
        setAdvanceDisableButton(false);
        toast.error("UPCs are missing.", {
          position: "top-right",
          autoClose: 1500,
        });

        const filesRows = csvFileRecords.slice(1);
        if (filesRows.length === 0) {
          setImportDisableButton(true);
          setAdvanceDisableButton(true);
          return ["No data was found"];
        }
        setCsvFileData(csvFileRecords);
        return ["bad"];
      } else {
        const fileLastRowsLength =
          csvFileRecords[csvFileRecords.length - 1].length;
        const fileFirstRowsLength = csvFileRecords[0].length;
        const fileRows =
          fileFirstRowsLength === fileLastRowsLength
            ? csvFileRecords.slice(1)
            : csvFileRecords.slice(1, csvFileRecords.length - 1);
        const upcIndex = fileHeaders.indexOf("upc");
        //const amountIndex = fileHeaders.indexOf("amount");
        //const dateIndex = fileHeaders.indexOf("date");
        var sendCsvData: any[] = [];

        for (var i in fileRows) {
          if (fileRows[i][upcIndex] !== "") {
            sendCsvData.push(fileRows[i][upcIndex]);
          }
        }
        setImportDisableButton(false);
        setAdvanceDisableButton(false);

        if (sendCsvData.length === 0) {
          setImportDisableButton(true);
          setAdvanceDisableButton(true);
          return ["No good data was found"];
        }
        //sendCsvData.unshift(fileHeaders);
        setCsvFileData(sendCsvData);
        return sendCsvData;
      }
    } else {
      setImportDisableButton(true);
      setAdvanceDisableButton(true);
      toast.error("Name or amount Header is missing.", {
        position: "top-right",
        autoClose: 15000,
      });
    }
  }

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Form onSubmit={handleSubmit} className="csv-form">
        <Form.Group>
          <div>
            {/*
            Example of an acceptable csv file:{" "}
            <a
              href="https://uploads-ssl.webflow.com/61dd2460f99ba00b4d9075ec/621c9fb7dd1021308b556765_acceptable-csv.png"
              target="_blank"
            >
              {" "}
              Example{" "}
            </a>
  */}
            A 'UPC' Column is necessary to ensure correct information.
          </div>
        </Form.Group>
        <Form.Group onChange={handleFileChange}>
          <CSVReader
            onUploadAccepted={(results: any) => {
              getCsvFileData(results);
              setZoneHover(false);
            }}
            onDragOver={(event: DragEvent) => {
              event.preventDefault();
              setZoneHover(true);
            }}
            onDragLeave={(event: DragEvent) => {
              event.preventDefault();
              setZoneHover(false);
            }}
          >
            {({
              getRootProps,
              acceptedFile,
              ProgressBar,
              getRemoveFileProps,
              Remove,
            }: any) => (
              <>
                <div
                  {...getRootProps()}
                  style={Object.assign(
                    {},
                    styles.zone,
                    zoneHover && styles.zoneHover
                  )}
                >
                  {acceptedFile ? (
                    <>
                      <div style={styles.file}>
                        <div style={styles.info}>
                          <span style={styles.size}>
                            {formatFileSize(acceptedFile.size)}
                          </span>
                          <span style={styles.name}>{acceptedFile.name}</span>
                        </div>
                        <div style={styles.progressBar}>
                          <ProgressBar />
                        </div>
                        <div
                          {...getRemoveFileProps()}
                          style={styles.remove}
                          onMouseOver={(event: Event) => {
                            event.preventDefault();
                            setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                          }}
                          onMouseOut={(event: Event) => {
                            event.preventDefault();
                            setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                          }}
                        >
                          <Remove color={removeHoverColor} />
                        </div>
                      </div>
                    </>
                  ) : (
                    "Drop CSV file here or click to upload"
                  )}
                </div>
              </>
            )}
          </CSVReader>
        </Form.Group>

        <Alert severity="error" className="mt-2">
          Trouble uploading your expenses? Email your file to{" "}
          <a href="mailto:support@sotira.co">support@sotira.co</a> or{" "}
          <a href="https://calendly.com/amritabhasin/15min?back=1&month=2022-08">
            schedule a meeting at this link
          </a>{" "}
          and we will upload it for you :)
        </Alert>
        <div
          className="d-flex justify-content-between align-items-center csv-dialog-wrapper"
          id="csv-dialog-wrapper"
        >
          {/*
          <Typography
            aria-owns={openTooltip ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            className="csv-advanced-button"
            id="csv-advanced-button"
          >
            <Button
              size="sm"
              disabled={advanceDisableButton}
              onClick={() => setOpenModal(true)}
              className={
                advanceDisableButton
                  ? "mt-3 float-left disable-button "
                  : "mt-3 float-left"
              }
            >
              Advanced
            </Button>
          </Typography>
            */}
          <Popover
            id="mouse-over-popover"
            open={openTooltip}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            {advanceDisableButton && (
              <Typography className="questionmark-popover text-white p-1">
                Please upload a csv/excel sheet first!!!
              </Typography>
            )}
          </Popover>
          <Button
            size="sm"
            type="submit"
            disabled={importDisableButton}
            className="mt-3 csv-sheet-button"
            style={styles.sheetButton}
            id="csv-sheet-button"
          >
            {loading ? (
              <>
                <CircularProgress
                  className="file-loader mr-3"
                  color="primary"
                />{" "}
                Uploading{" "}
              </>
            ) : (
              "Import CSV Sheet"
            )}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default UPopup;
