import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Typography, useTheme } from "@mui/material";

import {
  DataPreviewBox,
  NextButton,
  ContextHeading,
  HeadingText,
  ProgressDiv,
  CostText,
  SectionBox,
  InfoBox,
} from "./styles";

interface Sheet {
  id: string;
  name: string;
}

const GoogleSheets = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAcpt, setIsAcpt] = useState(true);
  const [sheets, setSheets] = useState<Sheet[]>([]);
  const [selectedSheetId, setSelectedSheetId] = useState<string | null>(null);

  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    const fetchToken = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");

      if (code) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}sheets/auth/google/callback`,
            { code }
          );
          const token = response.data.token;

          localStorage.setItem("gsheetToken", token);

          const sheetsResponse = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}sheets/list`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          setSheets(sheetsResponse.data.sheets);
        } catch (error) {
          console.error("Error fetching token:", error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchToken();
  }, [navigate]);

  const handleSheetSelect = async () => {
    if (selectedSheetId) {
      // Store the selected sheet ID or navigate with it as needed
      localStorage.setItem("selectedSheetId", selectedSheetId);
    }
    const authToken = localStorage.getItem("gsheetToken");
    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/set_sheet`,
        { selectedSheetId },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      // Create a link element to trigger download
    } catch (error) {
      console.error("Error selecting sheet:", error);
      setIsAcpt(false);
    }
  };

  const showOptions = () => {
    if (isAcpt) {
      if (isLoading) {
        return (
          <div>
            <ProgressDiv>
              {" "}
              <CircularProgress color="primary" />{" "}
            </ProgressDiv>
            <NextButton disabled>Access Sheets</NextButton>
          </div>
        );
      } else {
        if (sheets.length > 0) {
          return (
            <div>
              <select
                onChange={(e) => setSelectedSheetId(e.target.value)}
                value={selectedSheetId || ""}
                style={{
                  width: "100%",
                  padding: "10px",
                  margin: "10px 0",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                }}
              >
                <option value="" disabled>
                  Select a sheet
                </option>
                {sheets.map((sheet) => (
                  <option key={sheet.id} value={sheet.id}>
                    {sheet.name}
                  </option>
                ))}
              </select>
              <NextButton
                onClick={handleSheetSelect}
                disabled={!selectedSheetId}
              >
                Load Sheet Data
              </NextButton>
            </div>
          );
        } else {
          <NextButton disabled>
            No sheets found or authentication failed.
          </NextButton>;
        }
        return (
          <div>
            <NextButton>Sheet Info</NextButton>
          </div>
        );
      }
    } else {
      return (
        <Typography color="textPrimary">
          There has been an error please contact us at support@sotira.co
        </Typography>
      );
    }
  };

  return (
    <div>
      <ContextHeading
        sx={{ backgroundColor: theme.palette.background.default }}
      >
        <HeadingText color="textPrimary">Select Google Sheet </HeadingText>
      </ContextHeading>
      <InfoBox>
        <SectionBox>
          <CostText color="textPrimary">Select Sheet </CostText>
          <DataPreviewBox>
            <div> {showOptions()} </div>
          </DataPreviewBox>
        </SectionBox>
      </InfoBox>
    </div>
  );
};

export default GoogleSheets;
