import { styled, Button } from "@mui/material";

export const StyleButton = styled(Button)`
  width: 100%;
  background-color: #48b7a7;
  border: none;
  height: 38px;
  border-radius: 3px;
  color: white;
  align-items: center;
  font-family: "ProximaNova" !important;
  display: flex;
  font-size: 1rem;
  font-weight: bold;
  text-transform: none;
  &:disabled {
    background-color: rgb(137, 208, 198);
  }
`;
