import { useState } from "react";
import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import UPopup from "./uploadPop";

import {
  DataUploadBox,
  DataUploadText,
  UploadBox,
  ContentText,
  ButtonBox,
  UploadButton,
} from "./styles";

import { ReactComponent as ArrowIcon } from "../../assets/images/svg/Arrow.svg";

const DataUpload = () => {
  const [showUploadModal, setShowUploadModal] = useState(false);

  const handleClose = () => {
    setShowUploadModal(false);
  };

  return (
    <>
      <DataUploadBox>
        <UploadBox>
          <DataUploadText color="textPrimary">Data Upload</DataUploadText>
          <ContentText color="textPrimary">
            Finish the process by uploading your data.
          </ContentText>
        </UploadBox>
        <ButtonBox>
          <Dialog
            open={showUploadModal}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
              style: {
                overflowX: "hidden",
                maxWidth: "665px",
                width: "100%",
              },
            }}
          >
            <DialogTitle>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 0,
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <UPopup />
            </DialogContent>
          </Dialog>

          <UploadButton onClick={() => setShowUploadModal(true)}>
            <div className="button-icon">
              {" "}
              <ArrowIcon />
            </div>
            <span className="button-text">Upload</span>
          </UploadButton>
        </ButtonBox>
      </DataUploadBox>
    </>
  );
};

export default DataUpload;
